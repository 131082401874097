import { graphql } from "gatsby"
import React from "react"
import Card from "../components/Card"
import Layout from "../components/Layout"


interface HomeArgs{
  data: GatsbyTypes.RecipesQuery
}; 

export default function Home({data}: HomeArgs) {
  const recipes = data.recipes.nodes;
  return (
  <Layout>
    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 justify-items-center">
      {recipes.map((r)=>
        <Card 
          key={r.id}
          title={r!.frontmatter!.title!}
          tags={r!.frontmatter!.tags!}
          prepTime={r!.frontmatter!.prepTime!}
          to={"#"}
          summary={r!.frontmatter!.description!}
        />)}
    </div>
  </Layout>
)}

export const query = graphql`
query Recipes {
  recipes: allMarkdownRemark {
    nodes {
      frontmatter {
        date
        description
        featuredimage
        prepTime
        title
        tags
      }
      id
    }
  }
}
`