import React from 'react'

interface Args{
  className?: string
}
export default function Vegan({className=""}:Args) {
  return (
			<svg 
				version="1.1" 
				x="0px" y="0px"
				viewBox="0 0 463 463" 
				stroke="currentColor"
				className={className+" leading-sm uppercase p-2 bg-green-200 text-green-700 opacity-70 rounded-full"}>
					
				<path className="fill-current" d="M459.996,8.495c-0.667-2.707-2.781-4.821-5.488-5.488c-12.167-2.997-24.935-3.758-37.956-2.255
					c-30.703,3.54-60.875,18.755-84.961,42.84c-24.085,24.085-39.299,54.258-42.84,84.961c-1.331,11.544-0.877,22.889,1.332,33.794
					c-0.105,0.11-0.22,0.206-0.319,0.324c-33.014,39.222-55.366,81.822-70.263,123.99c-12.788-49.63-31.862-95.286-56.904-135.998
					C125.318,90.058,74.456,39.741,11.421,1.106C8.125-0.914,3.835-0.098,1.51,2.989c-2.325,3.087-1.924,7.435,0.927,10.044
					C79,83.108,132.937,164.55,162.752,255.096c12.556,38.133,29.249,100.395,29.249,200.404c0,4.143,3.358,7.5,7.5,7.5h32
					c4.142,0,7.5-3.357,7.5-7.5c0-5.437-0.029-11.003-0.059-16.688c-0.392-74.703-0.886-169.281,63.947-265.461
					c6.104,1.096,12.338,1.648,18.663,1.648c4.262-0.001,8.567-0.25,12.897-0.75c30.703-3.54,60.875-18.755,84.96-42.84
					c24.085-24.085,39.3-54.259,42.841-84.961C463.753,33.431,462.994,20.661,459.996,8.495z M177,250.405
					C155.416,184.853,121.562,123.98,75.987,68.61c28.689,26.611,53.381,56.662,73.833,89.912
					c28.266,45.954,48.724,98.473,60.829,156.102c-3.958,14.085-7.146,28.023-9.693,41.672
					C196.629,323.677,189.312,287.798,177,250.405z M223.942,438.891c0.017,3.072,0.032,6.109,0.043,9.109h-16.934
					c0.661-49.269,8.03-110.876,32.567-172.211C223.401,335.698,223.698,392.412,223.942,438.891z M447.35,44.73
					c-6.535,56.666-57.953,108.084-114.619,114.619c-6.813,0.787-13.53,0.844-20.09,0.205c24.296-32.846,56.551-65.776,99.375-98.066
					c3.308-2.493,3.967-7.196,1.473-10.504c-2.493-3.306-7.196-3.966-10.503-1.473c-40.411,30.47-73.538,62.817-99.801,97.534
					c-0.332-5.502-0.187-11.105,0.468-16.775c6.535-56.666,57.953-108.084,114.619-114.619c9.631-1.11,19.077-0.795,28.144,0.936
					C448.145,25.654,448.461,35.099,447.35,44.73z"/>
			</svg>
  )
}
