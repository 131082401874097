import { Link } from 'gatsby'
import React, { useMemo } from 'react'
import Clock from './icons/Clock';
import Flame from './icons/Flame';
import Vegan from './icons/Vegan';



const TAG_TYPES: BlogTypes.ObjectMap<BlogTypes.IconComponent> = {
  "hot": Flame,
  "vegan": Vegan
}

interface CardArgs{
  title: string,
  summary: string,
  prepTime: number,
  to: string,
  tags: string[],
}; 

export default function Card({title, summary, to, prepTime, tags}: CardArgs) {
  const [prepHours, prepMinutes]=useMemo(()=>prepTime?[Math.floor(prepTime/60), prepTime%60]:[0,0], [prepTime]);
  console.log({tags})
  const tagComponents = useMemo(()=>{
    const lowercaseTags = new Set(tags.map(v=>v.toLowerCase()))
    return Object.keys(TAG_TYPES)
    .filter(k=>lowercaseTags.has(k))
    .map(k=>({Val:TAG_TYPES[k], k}))
    .map(({Val, k}) => (<Val className="w-8 h-8 ml-1" key={k}></Val>))
  }, [tags])
  return (
    <Link className="bg-white shadow-md border border-gray-200 rounded-lg max-w-md mb-5 overflow-hidden transform transition duration-300 hover:scale-105" to={to}>
        <div className="relative">
          <img src="img/veg.jpg" alt=""></img>
          {prepTime && (
          <div className="block absolute bg-opacity-75 bg-white rounded-full px-3 py-1 text-md font-semibold text-gray-600 top-2 left-2">
            <Clock className="inline-block h-6 w-6"/>
            <span className="ml-2 inline-block">{prepHours?`${prepHours} h ${prepMinutes} min`:`${prepMinutes} min`}</span> 
          </div>
        )}
          <div className="flex absolute bottom-0 left-0 p-2 justify-end w-full">
            {
              tagComponents
            }
          </div>
        </div>

        <div className="p-5">
            <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">{title}</h5>
            <p className="font-normal text-gray-700 mb-3 line-clamp-4">{summary}</p>
        </div>
    </Link>
  )
}


// <div classNameName="bg-white rounded w-full mx-auto rounded-2xl shadow-lg">
  //   <div classNameName="bg-gray-200 h-48 p-3 overflow-hidden animate-pulse">
  //   </div>
  //   <div classNameName="h- p-3">
  //     <div classNameName="grid grid-cols-3 gap-4 mt-2">
  //       <div classNameName="h-8 bg-gray-200 rounded animate-pulse">
  //       </div>
  //       <div classNameName="h-8 bg-gray-200 rounded animate-pulse">
  //       </div>
  //       <div classNameName="h-8 bg-gray-200 rounded animate-pulse">
  //       </div>
  //       <div classNameName="h-8 col-span-2 bg-gray-200 rounded animate-pulse">
  //       </div>
  //       <div classNameName=" h-8 bg-gray-200 rounded animate-pulse">
  //       </div>
  //       <div classNameName="...">
  //       </div>
  //       <div classNameName="col-span-2 ...">
  //       </div>
  //     </div>
  //   </div>
  // </div>